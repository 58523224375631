import React from 'react';
import { Dropdown, Layout, Switch } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { ExpandMore, CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { helpers, images, types, } from 'helpers';
import { useTypedSelector } from 'Store/_Store';
import { useDispatch } from 'react-redux';
import { _logout, _setBusiness } from 'Store/slices/auth.slice';
import { useLazyBusinessQuery, useLazyMeQuery } from 'Store/api/auth.api';
import { _setUtils } from 'Store/slices/utils.slice';
import { useLazyConfigQuery, useLazyConfigurationQuery } from 'Store/api/utils.api';
import moment from 'moment';

const Header = React.lazy(() => import('./Header'));

const Leyout = ({ children }: { children: any }) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const { _auth: { user, token, business }, _utils: { env, meCache, businessCache }, _utils } = useTypedSelector((state) => state);

    const [getConfig] = useLazyConfigQuery();
    const [getMyDetails] = useLazyMeQuery();
    const [getMyBusiness] = useLazyBusinessQuery();
    const [getConfiguration] = useLazyConfigurationQuery();

    const isLoggedIn = !!(user.id && token && !location.pathname.includes('auth'));
    const isMobileMenuVisible = helpers.isMobile ? _utils.isMobileMenuVisible : true;

    React.useEffect(() => {
        if (token) {
            getMyDetails({ id: user.id, cache: moment().unix().toString() });
            getMyBusiness({ id: business.id, cache: moment().unix().toString() });
        }
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (token) {
            getConfig({});
            getMyDetails({ id: user.id, cache: meCache });
            getMyBusiness({ id: business.id, cache: businessCache });
            getConfiguration({});
        }
        // eslint-disable-next-line
    }, [token, meCache, businessCache]);

    const items = [
        { icon: images.NavOverview, label: `Overview`, path: '/', env: ['test', 'live'], },
        { icon: images.NavCollections, label: `Collections`, path: '/collections', permission: 'debit', env: ['test', 'live'], role: 'COLLECTIONS', },
        { icon: images.NavPayouts, label: `Payouts`, path: '/payouts', permission: 'credit', env: ['test', 'live'], role: 'PAYOUTS', },
        { icon: images.NavLinks, label: `Payment Links`, path: '/paylinks', env: ['test', 'live'], role: 'LINKS', },
        { icon: images.NavSettlements, label: `Settlements`, path: '/settlements', env: ['live'], role: 'SETTLEMENTS', },
        { icon: images.NavSettings, label: `Settings`, path: '/settings', env: ['test', 'live'], },
    ].filter(item => helpers.businessHasAccess(business, item.permission) && item.env.includes(env) && (!item.role || helpers.hasPermission(item.role)));

    const current = '/' + (location.pathname.split('/')[1] || '');
    const headerTitle = items.find((item: any) => item.path === current)?.label || '';
    const allBusiness = user?.teams?.map((team: types.Team) => team.business) || [];

    const switchBusiness = (row: types.Business) => {
        if (row.id !== business?.id) {
            dispatch(_setBusiness(row));
            dispatch(_setUtils({ key: 'loading', value: { spinning: true, primary: `Switching to ${row.name}` } }));
            setTimeout(() => {
                dispatch(_setUtils({ key: 'loading', value: { spinning: false, primary: `` } }));
                window.location.reload();
            }, 500);
        }
    }

    const logout = () => {
        mobileMenu(false);
        dispatch(_logout());
    }

    const mobileMenu = (value: boolean) => {
        dispatch(_setUtils({ key: 'isMobileMenuVisible', value }));
    }

    return (
        <React.Fragment>
            {isLoggedIn ? (
                <>
                    <Layout hasSider>
                        {isMobileMenuVisible && (
                            <div className="sider-overlay">
                                <div className="flex justify-end items-center px-5 pt-7">
                                    <img
                                        src={images.CloseWhite}
                                        alt="Close mobile menu"
                                        onClick={() => mobileMenu(!isMobileMenuVisible)}
                                    />
                                </div>
                            </div>
                        )}
                        <Layout.Sider
                            style={{
                                overflow: 'auto',
                                height: '100vh',
                                position: 'fixed',
                                left: 0,
                                top: 0,
                                bottom: 0,
                                display: isMobileMenuVisible ? 'block' : 'none',
                            }}
                        >
                            <div className="px-5 pt-6 mb-6 md:pt-10 md:mb-8 lg:pt-10 lg:mb-8">
                                <img src={images.Logo} alt="Open Gateway logo" />
                            </div>

                            <div className="mb-8 px-5 py-3 border-b border-t border-t-gray-700 border-b-gray-700">
                                <Dropdown
                                    menu={{
                                        items: [
                                            ...allBusiness.map(ab => {
                                                return {
                                                    key: ab.id,
                                                    label: (
                                                        <div
                                                            className={`py-1 ${ab.id === business?.id ? 'font-semibold primary' : ''} flex items-center`}
                                                            onClick={() => switchBusiness(ab)}
                                                        >
                                                            {ab.id === business?.id ? (
                                                                <CheckCircle fontSize="small" />
                                                            ) : (
                                                                <RadioButtonUnchecked fontSize="small" />
                                                            )}
                                                            <div className="ml-2">
                                                                <div>{ab.name}</div>
                                                                {business?.wallets?.length === 1 && (
                                                                    <div className="text-gray-500 text-xs">
                                                                        ({helpers.format.currency(helpers.businessBalance(types.Currencies.GHS, ab, env))})
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ),
                                                };
                                            }),
                                            { key: 'break1', label: <div className="border-b"></div>, disabled: true, role: 'SETTINGS_BUSINESS_ADD', },
                                            {
                                                key: 'addbusi',
                                                role: 'SETTINGS_BUSINESS_ADD',
                                                label: (
                                                    <div
                                                        className="py-2"
                                                        onClick={() => {
                                                            dispatch(_setUtils({ key: 'addBusiness', value: true }));
                                                            mobileMenu(false);
                                                        }}
                                                    >+ Add a business</div>
                                                ),
                                            },
                                        ].filter((item: any) => (!item.role || helpers.hasPermission(item.role)))
                                    }}
                                    trigger={['click']}
                                    disabled={!!!(allBusiness.length > 1 || helpers.hasPermission('SETTINGS_BUSINESS_ADD'))}
                                >
                                    <div className="text-white cursor-pointer font-light bg-slate-600s rounded py-2 px-3 flex justify-between items-center transition ease-in-out hover:border-gray-400">
                                        <div className="w-10/12">
                                            <div className="truncate text-xl font-medium">{business?.name}</div>
                                            {business?.wallets?.length === 1 && (
                                                <div className="text-gray-400 text-sm mt-1">
                                                    {helpers.format.currency(helpers.businessBalance(types.Currencies.GHS, business, env))}
                                                </div>
                                            )}
                                        </div>
                                        {(allBusiness.length > 1 || helpers.hasPermission('SETTINGS_BUSINESS_ADD')) && (
                                            <ExpandMore className="text-gray-400" />
                                        )}
                                    </div>
                                </Dropdown>
                            </div>
                            <div className="px-5 flex flex-col justify-between" style={{ height: `81vh` }}>
                                <div>
                                    {items.map(item => (
                                        item.path === 'break' ? (
                                            <div key={item.path} className="my-4 mx-4 border-b border-gray-600" />
                                        ) : (
                                            item.path.includes('http') ? (
                                                <a
                                                    href={item.path}
                                                    key={item.path}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className={`flex justify-start py-3 px-4 cursor-pointer nav-item transition ease-in-out pop`}
                                                >
                                                    {item.label}
                                                </a>
                                            ) : (
                                                <Link
                                                    to={item.path}
                                                    key={item.path}
                                                    className={`flex justify-start py-3 px-4 cursor-pointer nav-item transition ease-in-out pop ${current === item.path ? 'active' : ''}`}
                                                    onClick={() => mobileMenu(false)}
                                                >
                                                    <div><img src={item.icon as any} className="mr-2" alt={item.label} /></div>
                                                    <div>{item.label}</div>
                                                </Link>
                                            )
                                        )
                                    ))}
                                </div>
                                <div className="text-white">
                                    {helpers.isMobile && (
                                        <div className="rounded-md p-3 bg-dark2 flex items-center justify-between mx-5">
                                            <div className="mr-2 font-semibold leading-none capitalize">{env}</div>
                                            <Switch
                                                checked={!!(env === 'live')}
                                                disabled={!!(business.status !== types.TeamStatus.ACCEPTED)}
                                                onChange={e => {
                                                    dispatch(_setUtils({ key: 'env', value: e ? 'live' : 'test' }));
                                                    mobileMenu(false);
                                                    helpers.redirect(window.location.pathname);
                                                }}
                                            />
                                        </div>
                                    )}
                                    {helpers.hasPermission('SETTINGS_API') && (
                                        <a
                                            href="https://docs.ogateway.io/docs"
                                            target="_blank"
                                            rel="noreferrer"
                                            className={`flex justify-start py-3 px-4 cursor-pointer nav-item transition ease-in-out mx-5 pop`}
                                            onClick={() => mobileMenu(false)}
                                        >
                                            API Documentation
                                        </a>
                                    )}
                                    <div className="my-4 mx-4 border-b border-gray-600" />
                                    <span
                                        className={`flex justify-start py-3 px-4 cursor-pointer nav-item transition ease-in-out mx-5 pop`}
                                        onClick={logout}
                                    >
                                        <div><img src={images.Logout} className="mr-2" alt="Logout" /></div>
                                        <div>Logout</div>
                                    </span>
                                </div>
                            </div>
                        </Layout.Sider>
                        <Layout className="site-layout">
                            <div className="container">
                                <Header title={headerTitle} />
                                <Layout.Content style={{ overflow: 'initial' }}>
                                    <div className="p-5">
                                        {children}
                                    </div>
                                </Layout.Content>
                            </div>
                        </Layout>
                    </Layout>
                </>
            ) : (
                <div>{children}</div>
            )}
        </React.Fragment>
    );
}

export default Leyout;